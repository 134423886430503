import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import imgHero from "../images/gfx/hero-article.jpg"
import imgMobileHero from "../images/gfx/mobile-hero-2.jpg"

import imgPlasticBusPass from "../images/plastic-school-bus-pass-photo-id.jpg"
import imgDogWaitingForBusPass from "../images/tasty-school-bus-pass-for-dog.jpg"
import imgMobileTicket from "../images/mobile-ticket-for-school-transport.jpg"
import imgSaveTime from "../images/save-time-money-using-shuttleid.jpg"
import imgScreenCracked from "../images/mobile-screen-cracked.jpg"
import imgSafeguarding from "../images/safeguarding.jpg"
import imgProfileChrisBell from "../images/chris-bell-profile-square.jpg"
import "./index.css";
import "./case-study.css";
import "./article.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Nav from "../components/nav"
import Footer from "../components/footer"
import Contact from "../components/contact"

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Simple package demo" />
        <Nav activeTab="blog" />
        <div>
          <div>

              <div className="hero-article" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                <h1 className="blog-headline">Simple package demo</h1>
              </div>

              <div className="mobile-hero-article" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                <h1 className="blog-headline">Simple package demo</h1>
              </div>

              <div className="case-study" style={{ paddingTop: 1 }}>

                <div className="article">
                  <div className='article-intro article-inner'>

                    <p>What if we told you there was a simple way to create and send hundreds of bus passes in minutes, that they reach the passenger instantly, and they never need replacing?</p>

                    <div style={{ padding: '64.63% 0 0 0', position: 'relative' }}><iframe src="https://player.vimeo.com/video/739995574?h=6424fdbb38&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullscreen style={{ position: 'absolute', top:0, left:0, width: '100%', height: '100%' }} title="ShuttleID - Simple Package demo"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>

                    <p>Watch the video above by ShuttleID director Chris Bell on how to use the ShuttleID <Link className="link" to="/simple-package/">Simple Package</Link> to create and send digital school bus passes that can be used directly on a mobile phone or printed at home by the parent.</p>


                  </div>
                </div>
              </div>
              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage